<template>
  <div class="px-1 origins-table-h">
    <div
      v-for="row in details"
      :key="row.id"
      class="d-flex flex-row justify-content-between"
    >
      <span
        :title="row.label"
        class="text-truncate col-7 p-0"
      >{{ row.label }}</span>
      <span
        title="Visits"
        class="col-3 p-0"
      >
        <feather-icon
          class="text-primary"
          icon="EyeIcon"
        /> {{ row.hits | count }}
      </span>
      <span
        title="Conversions"
        class="col-2 p-0"
      >
        <feather-icon
          class="text-primary"
          icon="ShoppingCartIcon"
        /> {{ row.conversions | count }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    count: value => {
      if (!value) {
        return Number(0).toLocaleString()
      }
      return Number(value).toLocaleString()
    },
  },
  props: {
    details: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>
