<template>

  <div>
    <!-- Filters -->
    <visit-log-list-filters
      :origin-type-filter.sync="filters.originType"
      :visitor-type-filter.sync="filters.visitorType"
      :ecommerce-status-filter.sync="filters.ecommerceStatus"
    />

    <!-- Table Container Card -->
    <b-card-actions
      title="Visits log"
      class="mb-0"
      no-actions
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="pagination.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="table.search"
                debounce="500"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <vue-perfect-scrollbar
        class="mb-1 pb-1"
        style="max-height: calc(100vh - 450px);"
      >
        <b-table
          :items="visitorLog"
          striped
          borderless
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="table.sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="table.isSortDirDesc"
          :filter="table.search"
        >
          <!-- Column: User -->
          <template #cell(placeDate)="data">
            <b-media vertical-align="center">
              <template #aside>
                <country-flag
                  variant="light-primary"
                  :country="data.item.countryCode"
                  size="normal"
                  shadow
                  rounded
                />
              </template>
              <b-link
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.serverDatePretty }} {{ data.item.serverTimePretty }}
              </b-link>
              <small class="text-muted">{{ data.item.location }}</small>
            </b-media>
          </template>

          <!-- Column: Referrer type -->
          <template #cell(referrerType)="data">
            <div class="text-nowrap">

              <b-icon
                :icon="resolveRefferalTypeIcon(data.item.referrerType)"
                scale="1.4"
                class="mr-50 text-primary"
              />
              <span class="align-text-top text-capitalize">{{ data.item.referrerTypeName }}</span>

            </div>
            <b class="align-text-top">{{ resolveRefferalTypeText(data.item) }} </b>
          </template>

          <!-- Column: Role -->
          <template #cell(user)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.userId || data.item.visitorId }}</span>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <div class="text-nowrap status-icons">
              <span
                v-b-tooltip.hover
                class="status-icon"
                :title="`${data.item.visitorType}: ${data.item.visitCount} visits`"
              >
                <feather-icon
                  :icon="resolveVisitorIcon(data.item.visitorType)"
                  size="18"
                  class="mr-50 text-primary"
                />
              </span>
              <span
                v-b-tooltip.hover
                class="status-icon"
                :title="`Visitor uses ${data.item.deviceType} device`"
              >
                <feather-icon
                  :icon="resolveDeviceIcon(data.item.deviceType)"
                  size="18"
                  class="mr-50 text-primary"
                />
              </span>

              <span
                v-b-tooltip.hover
                class="icon-with-badge status-icon"
                title="Completad goal"
                :class="`text-${data.item.goalConversions > 0? 'success': 'muted'}`"
              >
                <feather-icon
                  icon="TargetIcon"
                  :badge="`${data.item.goalConversions}`"
                  size="18"
                  class="mr-50"
                />
              </span>

              <span
                v-b-tooltip.hover
                class="icon-with-badge status-icon"
                :title="`Purchased revenue: $${data.item.totalEcommerceRevenue}`"
                :class="`text-${data.item.totalEcommerceConversions > 0? 'success': 'muted'}`"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  :badge="`${data.item.totalEcommerceConversions}`"
                  size="18"
                  class="mr-50"
                />
              </span>

              <span
                v-if="data.item.totalAbandonedCarts > 0"
                v-b-tooltip.hover
                class="status-icon"
                :title="`Abandoned cart: $${data.item.totalAbandonedCartsRevenue}`"
              >
                <b-icon
                  icon="cart-x-fill"
                  scale="1.4"
                  class="mr-50 text-danger"
                />
              </span>

            </div>
          </template>
        </b-table>
      </vue-perfect-scrollbar>
      <div class="mx-2 mb-2">
        <b-row class="d-flex">

          <b-col
            cols="12"
            md="6"
            class="my-1 d-flex align-items-center justify-content-center justify-content-md-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-md-end"
          >
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="totalVisits"
              :per-page="pagination.perPage"
              first-number
              align="fill"
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card-actions>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow, BCol, BFormInput, BTable, BMedia, BLink, BPagination, VBTooltip,
} from 'bootstrap-vue'
import CountryFlag from 'vue-country-flag'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import VisitLogListFilters from '@/components/visitLog/VisitLogListFilters.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    VisitLogListFilters,
    CountryFlag,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BPagination,
    VuePerfectScrollbar,
    vSelect,
    BCardActions,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      // Pagination
      pagination: {
        currentPage: 1,
        perPage: 50,
      },
      table: {
        search: '',
        // Sort
        sortBy: 'placeDate',
        isSortDirDesc: true,
      },

      // Filters
      filters: {
        originType: '',
        ecommerceStatus: '',
        visitorType: '',
      },

      // Table
      tableColumns: [
        {
          key: 'placeDate',
          label: 'Place & Date',
          sortable: true,
          class: 'align-middle',
          width: '50px',
          formatter: (value, key, item) => item.firstActionTimestamp,
          sortByFormatted: true,
        },
        {
          key: 'referrerType',
          label: 'Origin',
          sortable: true,
          formatter: (value, key, item) => `${item.referrerTypeName} ${this.resolveRefferalTypeText(item)}`,
          sortByFormatted: true,
          class: 'align-middle',
        },
        {
          key: 'user',
          label: 'User',
          sortable: true,
          formatter: (value, key, item) => item.userId || item.visitorId,
          sortByFormatted: true,
          class: 'align-middle',
        },
        {
          key: 'status',
          label: 'Status',
          sortable: false,
          class: 'align-middle',
        },
      ],

      perPageOptions: [50, 100, 200, 500],

    }
  },
  computed: {
    ...mapState('visits', ['totalVisits', 'visitorLog']),
    dataMeta() {
      let toValue = this.pagination.currentPage * this.pagination.perPage
      toValue = toValue > this.totalVisits ? this.totalVisits : toValue
      return {
        from: (this.pagination.currentPage - 1) * this.pagination.perPage,
        to: toValue,
        of: this.totalVisits,
      }
    },
    filterSegmentString() {
      const { originType, ecommerceStatus, visitorType } = this.filters
      const filterSegmentString = [originType, ecommerceStatus, visitorType].filter(x => x !== '' && x != null)
        .join(';')
      return filterSegmentString
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetchData()
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('visits', ['fetchVisitLog']),
    resolveDeviceIcon(device) {
      const cleanDevice = device.toLowerCase()
      if (cleanDevice === 'desktop') return 'MonitorIcon'
      if (cleanDevice === 'smartphone') return 'SmartphoneIcon'
      if (cleanDevice.includes('ablet')) return 'TabletIcon'
      if (cleanDevice === 'tv') return 'TvIcon'
      return 'SquareIcon'
    },
    resolveVisitorIcon(visitor) {
      const cleanVisitor = visitor.toLowerCase()
      if (cleanVisitor === 'new') return 'UserPlusIcon'
      if (cleanVisitor === 'returning') return 'UsersIcon'
      return 'UserCheckIcon'
    },
    resolveRefferalTypeIcon(referrerType) {
      const cleanReferrerType = referrerType.toLowerCase()
      if (cleanReferrerType === 'direct') return 'signpost'
      if (cleanReferrerType === 'search') return 'search'
      if (cleanReferrerType === 'campaign') return 'badge-ad'
      if (cleanReferrerType === 'social') return 'facebook'
      if (cleanReferrerType === 'experiments') return 'lightning-fill'
      return 'link-45deg'
    },

    resolveRefferalTypeText(item) {
      const cleanReferrerType = item.referrerType.toLowerCase()
      if (cleanReferrerType === 'search') return `${item.referrerName} - ${item.referrerKeyword}`
      if (cleanReferrerType === 'campaign') return [item.referrerName, item.campaignMedium].join(' - ')
      return item.referrerName
    },

    // Data
    async fetchData() {
      await this.fetchVisitLog({
        page: this.pagination.currentPage,
        perPage: this.pagination.perPage,
        filtersSegment: this.filterSegmentString,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.status-icons{
  .status-icon{
    margin-right: .1em;
  }
  .icon-with-badge{
    margin-right: .8em;
    .badge.badge-up.badge-pill{
      font-size: 0.686rem;
      min-width: 1.229rem;
      min-height: 1.229rem;
    }
    .badge{
      background-color: #28c76f;;
    }
  }
}

.icon-with-badge.text-muted{
  margin-right: .8em;
  .badge{
    background-color: #b9b9c3 !important;
  }
}

</style>
