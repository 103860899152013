<template>
  <b-card-actions
    title="Filters"
    no-actions
  >
    <b-row>
      <b-col
        cols="12"
        md="4"
        class="mb-md-0 mb-2"
      >
        <label>Origin Type</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="originTypeFilter"
          :options="originTypeOptions"
          class="w-100"
          :reduce="val => val.value"
          @input="(val) => {$emit('update:originTypeFilter', val); }"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mb-md-0 mb-2"
      >
        <label>Visitor Type</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="visitorTypeFilter"
          :options="visitorTypeOptions"
          class="w-100"
          :reduce="val => val.value"
          @input="(val) =>{ $emit('update:visitorTypeFilter', val); }"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mb-md-0 mb-2"
      >
        <label>Ecommerce Status</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="ecommerceStatusFilter"
          :options="ecommerceStatusOptions"
          class="w-100"
          :reduce="val => val.value"
          @input="(val) => $emit('update:ecommerceStatusFilter', val)"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import {
  BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BCardActions,
  },
  props: {
    originTypeFilter: {
      type: [String, null],
      default: null,
    },
    visitorTypeFilter: {
      type: [String, null],
      default: null,
    },
    ecommerceStatusFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      originTypeOptions: [
        { label: 'Experiments & Campaigns', value: 'referrerType==campaign' },
        { label: 'Social Networks', value: 'referrerType==social' },
        { label: 'Search Engines', value: 'referrerType==search' },
        { label: 'Referral Website', value: 'referrerType==website' },
        { label: 'Direct Entry', value: 'referrerType==direct' },
      ],

      visitorTypeOptions: [
        { label: 'New User', value: 'visitorType==new' },
        { label: 'Returning User', value: 'visitorType==returning' },
        { label: 'Returning Customer', value: 'visitorType==returningCustomer' },
      ],

      ecommerceStatusOptions: [
        { label: 'None', value: 'visitEcommerceStatus==none' },
        { label: 'Ordered', value: 'visitEcommerceStatus==ordered,visitEcommerceStatus==orderedThenAbandonedCart' },
        { label: 'Abandoned Cart', value: 'visitEcommerceStatus==abandonedCart,visitEcommerceStatus==orderedThenAbandonedCart' },
      ],
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
