<template>
  <div>
    <b-sidebar
      id="sidebar-origin-and-visit-log"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      :title="`${selectedDate} - ${selectedOriginType}`"
      backdrop
      lazy
      right
      :visible="visibleSidebar"
      @shown="onOpen"
      @hidden="onHidden"
    >
      <template #default="">
        <b-overlay
          :show="overlayOn"
        >
          <users-origins
            class="mx-2"
            :values="origins"
          />
          <visit-log-list
            ref="visitlog"
            class="mx-2"
          />
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, BOverlay } from 'bootstrap-vue'

import VisitLogList from '@/components/visitLog/VisitLogList.vue'
import { mapActions, mapGetters } from 'vuex'
import { ON_STOP_OVERLAY, ON_START_OVERLAY } from '@/libs/eventBusConstants'
import UsersOrigins from '@/components/charts/UsersOrigins.vue'

export default {
  components: {
    VisitLogList, BSidebar, BOverlay, UsersOrigins,
  },
  data() {
    return {
      overlayOn: true,
      origins: [],
      visibleSidebar: false,
      originTypeOptions: {
        'Direct Entry': 'referrerType==direct',
        'Search Engines': 'referrerType==search',
        Campaigns: 'referrerType==campaign',
        'Social Networks': 'referrerType==social',
        'Referral Website': 'referrerType==website',
      },

      selectedOriginType: '',
      selectedDate: '',
    }
  },
  computed: {
    ...mapGetters('dataFilter', {
      selectedPeriod: 'getPeriod',
    }),
  },
  created() {
    this.$eventBus.$on(ON_START_OVERLAY, () => {
      this.overlayOn = true
    })
    this.$eventBus.$on(ON_STOP_OVERLAY, () => {
      this.overlayOn = false
    })
  },
  methods: {
    ...mapActions('visits', ['fetchVisitLog']),
    onOpen() {
      const body = document.getElementsByTagName('body')[0]
      body.classList.add('overflow-hidden')

      this.setDefaultFilters({
        date: this.selectedDateFilter,
      })
      this.$refs.visitlog.filters.originType = this.originTypeOptions[this.selectedOriginType]
      // this.$refs.visitlog.fetchData()
      this.fetchOrigins(this.selectedDateFilter)
        .then(response => {
          this.origins = response.map(origin => ({ ...origin, isVisible: origin.label === this.selectedOriginType }))
        })
    },
    onHidden() {
      // Generate a loading effect since the sidebar is opening
      this.overlayOn = true

      const body = document.getElementsByTagName('body')[0]
      body.classList.remove('overflow-hidden')

      this.visibleSidebar = false
    },
    ...mapActions('visits', {
      clearVisitLog: 'clearVisitLog',
      setDefaultFilters: 'setDefaultFilters',
    }),
    ...mapActions('home', [
      'fetchOrigins',
    ]),
    openSidebar({ date, originType }) {
      // Clear the visit log and origins data
      this.clearVisitLog()
      this.origins = []

      // Set the selected date and origin type
      this.selectedDateFilter = date
      if (this.selectedPeriod === 'week') {
        // eslint-disable-next-line prefer-destructuring
        this.selectedDateFilter = date.split(',')[0]
      } if (this.selectedPeriod === 'month') {
        this.selectedDateFilter = `${date}-01`
      }

      this.selectedOriginType = originType
      this.selectedDate = date

      // Open sidebar
      this.visibleSidebar = true
    },
  },
}
</script>
<style lang="scss">
#sidebar-origin-and-visit-log{
  width: 50%;
  min-width: 60em;

  @media screen and (max-width: 991px) {
    width: 90%;
    min-width: 0em;
  }
}
</style>
