<template>
  <div class="flowCard box-shadow-2">
    <div
      class="colorIndicator"
      :class="'bg-' + bounceStatus"
    />
    <div
      v-if="flowStage.metrics"
      class="cardContainer p-1"
    >
      <div class="section">
        <span
          :class="bounceWarningVisibility"
          class="text-danger flex-grow-1"
        >
          <feather-icon
            slot="button"
            icon="AlertTriangleIcon"
            size="21"
          />Bottleneck</span>
        <span
          class="right"
          @click="showModal"
        >
        <!--
          <b-link
            :to="{path:'#' + flowStage.id}"
          >Full view</b-link>
           -->
        </span>
      </div>
      <div class="section my-1">
        <div class="flex-grow-1">
          <h4 class="cardName">
            {{ flowStage.name }}
          </h4>
          <div class="metrics-container">
            <p class="mb-0 metric">
              <b>{{ flowStage.metrics.nb_visits | numberFormat }}</b> Visitors
            </p>
            <p class="mb-0 metric">
              <b>{{ flowStage.metrics.bounce_count | numberFormat }}</b> Bounces
            </p>
            <p class="mb-0 metric">
              <b>{{ flowStage.metrics.conversion_rate | formatRationPorcentage }}</b> Conversion rate
            </p>
          </div>
        </div>
        <div class="right">
          <p>
            <span class="dropout h4 d-block mb-0">{{ flowStage.metrics.dropout_rate | formatRationPorcentage }}</span><span class="metric">Drop Off</span>
          </p>
        </div>
      </div>
    </div>
    <stage-details-modal
      ref="fullViewModal"
      :flow-stage="flowStage"
    />
  </div>
</template>

<script>
import { VBModal } from 'bootstrap-vue'
import StageDetailsModal from '../components/StageDetailsModal.vue'

export default {
  components: {
    // BLink,
    StageDetailsModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  filters: {
    formatRationPorcentage(ratio) {
      return `${Number(ratio * 100).toFixed(1)}%`
    },
    numberFormat: value => {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
      if (!value) {
        return Number(0).toLocaleString()
      }
      return Number(value).toLocaleString()
    },
  },
  props: {
    flowStage: {
      type: Object,
      default: null,
    },
  },
  computed: {
    bouncerRate() {
      if (!this.flowStage.metrics || !this.flowStage.metrics.dropout_rate) {
        return 0
      }
      const bounce = parseInt(this.flowStage.metrics.dropout_rate, 10)
      return bounce
    },
    bounceStatus() {
      if (this.flowStage.metrics.dropout_rate > 0.5) {
        return 'danger'
      }
      if (this.flowStage.metrics.dropout_rate > 0.3) {
        return 'warning'
      }
      return 'success'
    },
    bounceWarningVisibility() {
      if (this.bounceStatus === 'danger') {
        return 'visible'
      }
      return 'invisible'
    },
  },
  methods: {
    showModal() {
      this.$refs.fullViewModal.openModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.flowCard {
  background-color: #fff;
  margin: 1em 1em 1em 1em;
  min-width: 270px;
  width: 270px;
  height: 150px;
  border-radius: 0 6px 6px 0;
  display: flex;

  .colorIndicator {
    height: 100%;
    margin: 0px;
    width: 15px;
    min-width: 15px;
  }
  .cardContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .section {
      display: flex;
      align-items: center;

      .metrics-container {
        margin-left: .5rem;
      }

      .dropout,
      .cardName {
        font-size: 1.4em;
        font-weight: bold;
      }
      .right {
        width: 35%;
        text-align: center;
      }
      .metric{
        font-size: .85rem;
        line-height: 1.5em;
      }
    }
  }
}
</style>
