<template>
  <b-card
    no-body
    class="visitor-behavior-card"
  >
    <b-card-header>
      <b-card-title>{{ title }} <small>| overview</small></b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body d-flex justify-content-center align-items-center">
      <b-row>
        <b-col
          v-for="item in items"
          :key="item.icon"
          v-b-toggle.sidebar-visit-log
          cols="8"
          sm="4"
          offset="2"
          offset-sm="0"
          class="mb-2 mb-xl-2 visitor-behavior-item"
          :class="item.customClass"
          @click="onClick(item.segment)"
        >
          <b-media
            no-body
          >
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title | formatNumber }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia,
  VBToggle, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  filters: {
    formatNumber(value) {
      const miles = value / 1000
      const milions = value / 1000000
      if (miles < 1000 && miles > 1) {
        return miles.toFixed(2).toString().concat('K')
      }
      if (milions > 1) {
        return milions.toFixed(2).toString().concat('M')
      }
      return value.toString().concat('')
    },
  },
  props: {
    behavior: {
      type: Object,
      default() {
        return {
          total: 0,
          dropout: 0,
          conversions: 0,
        }
      },
    },
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'EyeIcon',
          color: 'light-primary',
          title: 0,
          subtitle: 'Total visitors',
          customClass: 'mb-2 mb-xl-0',
          value: 'total',
          segment: null,
        },
        {
          icon: 'LogOutIcon',
          color: 'light-danger',
          title: 0,
          subtitle: 'Bounce visitors',
          customClass: 'mb-2 mb-xl-0',
          value: 'dropout',
          segment: 'actions==1', // 'interactions==1',
        },
        {
          icon: 'ShoppingCartIcon',
          color: 'light-warning',
          title: 0,
          subtitle: 'Converted visitors',
          customClass: 'mb-2 mb-sm-0',
          value: 'converted',
          segment: 'visitEcommerceStatus==ordered,visitEcommerceStatus==orderedThenAbandonedCart,visitConverted==1',
        },
      ],
      title: 'Visitors behavior',
      visitorsData: {
      },
    }
  },
  computed: {
    items() {
      const tmp = [...this.statisticsItems]
      tmp[0].title = this.behavior.total
      tmp[1].title = this.behavior.dropout
      tmp[2].title = this.behavior.conversions
      return tmp
    },
  },
  methods: {
    ...mapActions('visits', {
      clearVisitLog: 'clearVisitLog',
      setDefaultFilters: 'setDefaultFilters',
    }),
    onClick(segment) {
      this.clearVisitLog()
      this.setDefaultFilters({
        segment,
      })
    },
  },

}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/style';
.visitor-behavior-card{
  height: $top-home-cards-height;
}
</style>
