<template>
  <!-- basic modal -->
  <b-modal
    ref="modal"
    class="heatmap"
    :title="flowStage.name"
    no-close-on-backdrop
    ok-only
    ok-title="Accept"
  >
    <b-row>
      <b-col
        lg="12"
        xl="12"
      >
        <b-row>
          <b-col
            md="12"
            lg="12"
            class="mb-1"
          >
            <stage-visitors-dropout
              :metrics="computedMetrics"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
            lg="6"
            class="mb-1 pr-lg-0"
          >
            <stage-origins
              :origins="origins"
            />
          </b-col>
          <b-col
            md="12"
            lg="6"
            class="mb-1"
          >
            <stage-outlinks
              :links="links"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mb-1">
            <!--
              <stage-attribution
              :values="attribution"
              />
            -->
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BRow, BCol,
} from 'bootstrap-vue'
import StageVisitorsDropout from '../charts/StageVisitorsDropout.vue'
import StageOrigins from '../charts/StageOrigins.vue'
import StageOutlinks from '../charts/StageOutlinks.vue'
// import StageAttribution from '../charts/StageAttribution.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    StageVisitorsDropout,
    StageOrigins,
    StageOutlinks,
    // StageAttribution,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    flowStage: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      links: [],
      origins: [],
      attribution: {
        own: 0,
        others: 0,
      },
      visitors: 0,
      returning: 0,
      newVisits: 0,
      dropout: 0,
    }
  },
  computed: {
    computedMetrics() {
      const {
        nb_visits: visits,
        nb_visits_converted: converted,
        bounce_count: bounceCount,
        dropout_count: dropoutCount,
      } = this.flowStage?.metrics || {}
      return {
        visits,
        bounceCount,
        dropoutCount,
        converted,
        returningVisits: this.returning,
        newVisits: this.newVisits,
      }
    },
  },
  methods: {
    ...mapActions('home', [
      'fetchStageVisits',
      'fetchStageOrigins',
      'fetchStageOutlinks',
      'fetchAttribution',
    ]),
    openModal() {
      const stage = this.flowStage
      this.fetchStageVisits(stage)
        .then(({ visits, returning, newVisits }) => {
          this.visitors = visits
          this.returning = returning
          this.newVisits = newVisits
        })
      this.fetchStageOrigins(stage)
        .then(response => {
          this.origins = response
        })
      this.fetchStageOutlinks(stage)
        .then(response => {
          this.links = response
        })
      this.fetchAttribution(stage)
        .then(response => {
          this.attribution = response
        })
      this.$refs.modal.show()
    },
  },
}
</script>

<style lang="scss">
.modal{
  padding: 0 1rem !important;
}
.modal-dialog {
  max-width: inherit !important;
  margin:  1.75rem 1.75rem .4rem;

  /* smartphones, touchscreens */
  @media (hover: none){
    margin: 1.25rem .25rem;
  }
}
</style>
