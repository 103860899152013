<template>
  <b-card
    v-if="hasData"
    no-body
  >
    <b-card-header>
      <b-card-title>{{ title }} <small>| Where the visitors are droping?</small></b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body d-flex align-items-center">
      <vue-perfect-scrollbar
        class="d-flex flow"
        tagname="div"
      >
        <stage-flow-card
          v-for="stage in flow.flowStages"
          :key="stage.idStage"
          :flow-stage="stage"
        />
        <goal-flow-card

          v-if="hasGoal"
          :goal-stage="flow.goal"
        />

      </vue-perfect-scrollbar>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import StageFlowCard from './stages/StageFlowCard.vue'
import GoalFlowCard from './stages/GoalFlowCard.vue'
import { flowService } from '@/services/spreaadRestApi'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    VuePerfectScrollbar,
    BCardHeader,
    BCardBody,
    StageFlowCard,
    BCardTitle,
    GoalFlowCard,
  },
  data() {
    return {
      flow: {
        flowStages: [],
        idFlow: null,
        idgoal: null,
        goal: null,
      },
      title: 'Flow and Bottleneck',
    }
  },
  computed: {
    hasData() {
      return this.flow.flowStages.length !== 0
    },
    hasGoal() {
      return this.flow.goal
    },
    ...mapGetters('dataFilter', {
      selectedGoal: 'getGoalId',
      selectedSite: 'getSiteId',
      selectedDate: 'getDate',
      selectedPeriod: 'getPeriod',
    }),
  },
  created() {
    this.loadFlow()
  },
  mounted() {
    this.$eventBus.$on('filterChanges', () => {
      this.flow.flowStages = []
      this.loadFlow()
    })
  },
  methods: {
    loadFlow() {
      flowService.getFlow(this.selectedDate, this.selectedPeriod, this.selectedSite, this.selectedGoal)
        .then(response => {
          // This prevent Vue Reactivity bug
          this.flow = { ...this.flow, ...response }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
.flow {
  @include media-breakpoint-down(xs) {
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
}
</style>
