<template>
  <div>
    <b-row>
      <b-col
        md="12"
        lg="6"
      >
        <home-visitors-behavior :behavior="behavior" />
      </b-col>
      <b-col
        md="12"
        lg="6"
      >
        <home-conversion-rate
          :xaxis="conversion.categories"
          :series="conversion.series"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <home-acquisition-growth-origins />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <home-visitor-entries />
      </b-col>
      <b-col lg="12">
        <flow />
      </b-col>
    </b-row>

    <visit-log-sidebar />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import HomeVisitorsBehavior from './home/HomeVisitorsBehavior.vue'
import HomeConversionRate from './home/HomeConversionRate.vue'
import HomeVisitorEntries from './home/HomeVisitorEntries.vue'
import HomeAcquisitionGrowthOrigins from './home/HomeAcquisitionGrowthOrigins.vue'
import VisitLogSidebar from '@/components/visitLog/VisitLogSidebar.vue'
import Flow from './bottleneck/Flow.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    VisitLogSidebar,
    HomeConversionRate,
    HomeVisitorsBehavior,
    HomeVisitorEntries,
    Flow,
    HomeAcquisitionGrowthOrigins,
  },
  data() {
    return {
      site: '',
      behavior: {
        total: 0,
        dropout: 0,
        conversions: 0,
      },
      conversion: {
        series: [],
        categories: [],
      },
      overlayOn: true,
    }
  },
  created() {
    this.load()
    this.updateNavbar({ showGoalPicker: true, showDatePicker: true })
  },
  mounted() {
    this.$eventBus.$on('filterChanges', () => {
      this.load()
    })
  },
  methods: {
    ...mapActions('appConfig', {
      updateNavbar: 'updateNavbarConfig',
    }),
    ...mapActions('home', {
      fetchBehaviour: 'fetchBehaviour',
      fetchConversion: 'fetchConversion',
    }),
    load() {
      this.fetchBehaviour().then(response => {
        this.behavior = response
      })
      this.fetchConversion().then(response => {
        this.conversion = response
      })
    },
  },
}
</script>
