<template>
  <div>
    <b-sidebar
      id="sidebar-visit-log"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      title="Visit Log"
      backdrop
      lazy
      right
      @shown="getVisitLogData"
      @hidden="onHidden"
    >
      <template #default="">
        <b-overlay
          :show="overlayOn"
        >
          <visit-log-list ref="visitlog" />
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BOverlay } from 'bootstrap-vue'

import VisitLogList from '@/components/visitLog/VisitLogList.vue'
import { mapActions } from 'vuex'
import { ON_STOP_OVERLAY, ON_START_OVERLAY } from '@/libs/eventBusConstants'

export default {
  components: {
    VisitLogList, BSidebar, BOverlay,
  },
  data() {
    return {
      overlayOn: true,
    }
  },
  created() {
    this.$eventBus.$on(ON_START_OVERLAY, () => {
      this.overlayOn = true
    })
    this.$eventBus.$on(ON_STOP_OVERLAY, () => {
      this.overlayOn = false
    })
  },
  methods: {
    ...mapActions('visits', ['fetchVisitLog']),
    getVisitLogData() {
      const body = document.getElementsByTagName('body')[0]
      body.classList.add('overflow-hidden')

      this.$refs.visitlog.fetchData()
    },
    onHidden() {
      // Generate a loading effect since the sidebar is opening
      this.overlayOn = true

      const body = document.getElementsByTagName('body')[0]
      body.classList.remove('overflow-hidden')
    },

  },
}
</script>
<style lang="scss">
#sidebar-visit-log{
  width: 50%;
  min-width: 60em;

  @media screen and (max-width: 991px) {
    width: 90%;
    min-width: 0em;
  }
}
</style>
