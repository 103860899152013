<template>
  <b-card
    no-body
    class="m-0 visitors-map-card"
  >
    <b-card-header class="px-0">
      <b-card-title>
        Visitors map
      </b-card-title>
    </b-card-header>
    <b-card-body class="px-0">
      <GChart
        :settings="{ version: 'upcoming', packages: ['geochart' ], mapsApiKey: key }"
        :data="chartData"
        :options="options"
        :create-chart="(el, gcharts) => new gcharts.visualization.GeoChart(el)"
        :resize-debounce="chartUpdateTime"
        @ready="onChartReady"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import { GChart } from 'vue-google-charts'
import { apiKeys } from '@/config'

export default {
  components: {
    BCard, BCardHeader, BCardTitle, BCardBody, GChart,
  },
  props: {
    values: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      gcharts: null,
      chartUpdateTime: 500,
    }
  },
  computed: {
    chartData() {
      return [
        ['Country', 'Visits', { role: 'tooltip', p: { html: true } }],
        ...this.values.map(({
          label, visits, bounce, seconds,
        }) => ([label, visits, this.renderTooltip(visits, bounce, seconds)])),
      ]
    },
    key() {
      return apiKeys.googleMapApi
    },
    options() {
      if (!this.gcharts) return null
      return {
        colorAxis: {
          minValue: 0,
          colors: ['#96AFFF', '#325ff6', '#4632f6', '#3632f6'],
        },
        tooltip: {
          isHtml: true,
        },
      }
    },
  },
  methods: {
    onChartReady(chart, google) {
      this.gcharts = google
    },
    renderTooltip(visits, bounce, seconds) {
      let aux = bounce || 0
      aux = (aux * 100).toFixed()
      const time = seconds || 0
      return `<div class="text-nowrap"><strong>Visits</strong>: ${visits}</div>\
        <div class="text-nowrap"><strong>Bounce rate</strong>: ${aux}%</div>\
        <div class="text-nowrap"><strong>Avg. time</strong>: ${time}</div>`
    },
  },
}
</script>
<style lang="scss" scoped>
@import  '@/assets/scss/style';
.visitors-map-card{
  height: $origin-cards-height;
}
</style>
