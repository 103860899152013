<template>
  <b-card
    no-body
    class="conversion-rate-card"
  >
    <b-card-header>
      <b-card-title>Historical Conversion rate <small>| detailed</small></b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row class="flex-sm-wrap flex-md-nowrap h-100 align-items-center justify-content-center">
        <div
          class="conversion-rate-chart px-1 px-md-0 flex-grow-1"
        >
          <vue-apex-charts
            v-if="hasData"
            ref="lineChart"
            type="line"
            height="100%"
            width="100%"
            :options="options"
            :series="getSeries"
          />
        </div>
        <div
          class="px-0 conversion-percent"
        >
          <statistic-card-vertical
            class="p-0 m-0"
            color="success"
            icon="HeartIcon"
            :statistic="computeLastRate | formatRationPorcentage"
            statistic-title="Conversion rate"
          />
        </div>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

const SERIES_NAMES = {
  nb_conversions: 'Conversions',
  nb_visits_converted: 'Converted visitors',
  conversion_rate: 'Conversion rate',
  revenue: 'Revenue',
}

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    VueApexCharts,
    StatisticCardVertical,
  },
  filters: {
    formatRationPorcentage(ratio) {
      return `${Number(ratio * 100).toFixed(2)} %`
    },
  },
  props: {
    series: {
      type: Array,
      required: true,
      default: () => [],
    },
    xaxis: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      text: 'Alpha',
    }
  },
  computed: {
    hasData() {
      return this.series.length !== 0
    },
    getSeries() {
      const rateSerie = this.series.find(item => item.name === 'conversion_rate')
      if (rateSerie !== null && rateSerie.data?.length > 0) {
        return [rateSerie]
      }
      return []
    },
    computeLastRate() {
      let value = this.series.find(serie => serie.name === 'conversion_rate')
      value = value && value.data
      value = Array.isArray(value) && value[value.length - 1]
      return value || 0
    },
    getLang() {
      if (navigator.languages !== undefined) { return navigator.languages[0] }
      return navigator.language
    },
    options() {
      return {
        color: ['#28c76f'],
        legend: {
          show: false,
        },
        chart: {
          dropShadow: {
            blur: 4,
            enabled: true,
            left: 0,
            opacity: 0.1,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          id: 'vuechart-conversion-rate',
        },
        colors: [
          '#28c76f',
        ],
        fill: {
          gradient: {
            gradientToColors: [
              '#55DD92',
            ],
            opacityFrom: 1,
            opacityTo: 1,
            shade: 'dark',
            shadeIntensity: 1,
            stops: [
              0,
              100,
              100,
              100,
            ],
            type: 'horizontal',
          },
          type: 'gradient',
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
          show: false,
        },
        markers: {
          hover: {
            size: 5,
          },
          size: 0,
        },
        stroke: {
          curve: 'smooth',
          width: 5,
        },
        xaxis: {
          categories: this.xaxis,
          tickAmount: 4,
          labels: {
            formatter: value => this.xaxisFormater(value),
          },
        },
        yaxis: {
          min: 0,
          max(max) {
            return max <= 0.03 ? 0.03 : max
          },
          show: true,
          tickAmount: 5,
          labels: {
            formatter(value) {
              return `${Number(value * 100).toFixed(2)} %`
            },
          },
        },
        noData: {
          text: 'No data available',
          align: 'center',
          verticalAlign: 'middle',
        },
        tooltip: {
          custom: ({ dataPointIndex }) => {
            const values = this.series.map(({ data, name }) => ({
              data: data[dataPointIndex],
              name: SERIES_NAMES[name],
            }))
            const rate = values.find(value => value.name === 'Conversion rate')
            if (rate) {
              rate.data = `${Number(rate.data * 100).toFixed(2)} %`
            }
            const tt = this.createHTMLElement('div', 'apexcharts-tooltip-text')

            const ttRowHeader = this.createHTMLElement('div', 'row apexcharts-tooltip-header')
            ttRowHeader.appendChild(this.createHTMLElement('span', 'col', this.xaxis[dataPointIndex]))
            tt.appendChild(ttRowHeader)

            values.forEach(value => {
              const ttRow = this.createHTMLElement('div', 'row')
              const txtLabel = this.createHTMLElement('span', 'apexchart-tooltip-text-label col', value.name)

              const isCurrency = value.name === 'Revenue'
              const tooltipValue = this.numberFormat(value.data, isCurrency)
              const txtValue = this.createHTMLElement('span', 'apexcharts-tooltip-text-value col', tooltipValue)

              ttRow.appendChild(txtLabel)
              ttRow.appendChild(txtValue)
              tt.appendChild(ttRow)
            })
            return tt.outerHTML
          },
        },
      }
    },
  },
  methods: {
    xaxisFormater(value) {
      if (value != null) { // Null or undefined
        if (typeof value === 'string' && value.includes(',')) {
          const arr = value.split(',', 2)
          return arr[1]
        }
        return value
      }
      return ''
    },
    createHTMLElement(tag, classes, textContent) {
      const htmlElement = document.createElement(tag)
      htmlElement.setAttribute('class', classes)
      if (textContent) {
        htmlElement.textContent = textContent
      }
      return htmlElement
    },
    numberFormat(value = 0, isCurrency = false) {
      if (typeof value !== 'number') {
        return value
      }
      const options = {}
      if (isCurrency) {
        options.currency = 'MXN'
        options.style = 'currency'
        options.currencyDisplay = 'narrowSymbol'
      }

      return Number(value).toLocaleString(this.getLang, options)
    },
  },
}
</script>

<style lang="scss" >
@import  '@/assets/scss/style';
.conversion-rate-card{
  height: $top-home-cards-height;
  @media (max-width: 768px) {
    height: auto;
  }

  .conversion-rate-chart{
    height: 100%;
    @media (max-width: 768px) {
      min-height: 20rem;
      position: relative;
      width: 100%;
    }
  }
  .apexcharts-xaxistooltip-bottom{
    opacity: 0 !important;
  }
  .apexcharts-tooltip {
    background: #FFF;
    color: rgb(0, 0, 0);
    border: 1px solid rgb(11, 146, 45);
    margin: .5rem !important;
    padding: .5rem !important;

    .apexcharts-tooltip-header{
      text-align: center;
      padding-bottom: .5rem;
      padding-top: .2rem;
      font-weight: 900;
      border-bottom: 1px solid rgb(11, 146, 45);
    }

    .apexcharts-tooltip-text-value{
      text-align: right;
    }
  }
  .conversion-percent{
    width: 10rem !important;
    flex-basis: 10rem;
  }
}
</style>
