<template>
  <b-card
    no-body
    class="attribution-origins"
  >
    <b-card-header>
      <!-- title and legend -->
      <b-card-title class="mb-50">
        Historical Acquisition Growth <small>| Converted visitor by origin</small>
      </b-card-title>
      <!--/ title and legend -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="hasData"
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
    <home-acquisition-growth-origins-sidebar ref="originDetailsSidebar" />
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { mapActions } from 'vuex'
import HomeAcquisitionGrowthOriginsSidebar from './HomeAcquisitionGrowthOriginsSidebar.vue'

const SERIES_NAMES = {
  nb_conversions: 'Conversions',
  nb_visits_converted: 'Converted visitors',
  conversion_rate: 'Conversion rate',
  revenue: 'Revenue',
  nb_visits: 'Visits',
}

// colors
const chartColors = {
  colors: [
    '#FFA1A1',
    '#826af9',
    '#00d4bd',
    '#ffe700',
    '#2b9bf4',
    '#a8ff78',
  ],
}
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    BCardTitle,
    HomeAcquisitionGrowthOriginsSidebar,
  },
  data() {
    return {
      series: [],
      categories: [],
    }
  },
  computed: {
    hasData() {
      return this.series != null && this.series.length > 0
    },
    getLang() {
      if (navigator.languages !== undefined) { return navigator.languages[0] }
      return navigator.language
    },
    chartOptions() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
          id: 'vuechart-conversion-rate',
          events: {
            click: (_event, _chartContext, config) => {
            // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
              const { seriesIndex, dataPointIndex } = config
              if (seriesIndex >= 0 && dataPointIndex >= 0) {
                const originType = this.series[seriesIndex].name
                const date = this.categories[dataPointIndex]

                this.$refs.originDetailsSidebar.openSidebar({ date, originType })
              }
            },
          },
        },
        colors: chartColors.colors, // TODO: CHECK DEFAULT COLORS
        plotOptions: {
          bar: {
            columnWidth: '90%',
            // columnWidth: '55%',
            colors: {
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'left',
        },
        stroke: {
          show: true,
          colors: ['transparent'],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: this.categories,
          labels: {
            formatter: value => this.xaxisFormater(value),
          },
        },
        yaxis: {
          min: 0,
          max(max) {
            return max <= 5 ? 5 : max
          },
          labels: {
            formatter(val) {
              return val?.toFixed(0) || 0
            },
          },
        },
        noData: {
          text: 'No data available',
          align: 'center',
          verticalAlign: 'middle',
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex }) => {
            const values = Object.entries(this.series[seriesIndex].label).map(([name, data]) => ({
              name: SERIES_NAMES[name],
              data: data[dataPointIndex],
            }))
            const rate = values.find(value => value.name === 'Conversion rate')
            if (rate) {
              rate.data = `${Number(rate.data * 100).toFixed(2)} %`
            }
            const tt = this.createHTMLElement('div', 'apexcharts-tooltip-text')

            const ttRowHeader = this.createHTMLElement('div', 'row apexcharts-tooltip-header')
            ttRowHeader.appendChild(this.createHTMLElement('span', 'col', this.series[seriesIndex].name))
            ttRowHeader.appendChild(this.createHTMLElement('span', 'col', this.categories[dataPointIndex]))
            tt.appendChild(ttRowHeader)

            values.forEach(value => {
              const ttRow = this.createHTMLElement('div', 'row')
              const txtLabel = this.createHTMLElement('span', 'apexchart-tooltip-text-label col', value.name)

              const isCurrency = value.name === 'Revenue'
              const tooltipValue = this.numberFormat(value.data, isCurrency)
              const txtValue = this.createHTMLElement('span', 'apexcharts-tooltip-text-value col', tooltipValue)

              ttRow.appendChild(txtLabel)
              ttRow.appendChild(txtValue)
              tt.appendChild(ttRow)
            })
            return tt.outerHTML
          },
        },
      }
    },
  },
  created() {
    this.load()
  },
  mounted() {
    this.$eventBus.$on('filterChanges', () => {
      this.load()
    })
  },
  methods: {
    ...mapActions('home', {
      fetchAttributionOrigins: 'fetchAttributionOrigins',
    }),
    createHTMLElement(tag, classes, textContent) {
      const htmlElement = document.createElement(tag)
      htmlElement.setAttribute('class', classes)
      if (textContent) {
        htmlElement.textContent = textContent
      }
      return htmlElement
    },
    xaxisFormater(value) {
      if (typeof value === 'string' && value != null) {
        if (value.includes(',')) {
          const arr = value.split(',', 2)
          return arr[1]
        }
        return value
      }
      return ''
    },
    numberFormat(value = 0, isCurrency = false) {
      if (typeof value !== 'number') {
        return value
      }
      const options = {}
      if (isCurrency) {
        options.currency = 'MXN'
        options.style = 'currency'
        options.currencyDisplay = 'narrowSymbol'
      }
      return Number(value).toLocaleString(this.getLang, options)
    },
    load() {
      this.fetchAttributionOrigins().then(({ categories, series }) => {
        this.categories = categories
        this.series = series
      })
    },
  },
}
</script>

<style lang="scss" >
@import  '@/assets/scss/style';
.attribution-origins {
  .apexcharts-xaxistooltip-bottom{
    opacity: 0 !important;
  }
  .apexcharts-tooltip {
    background: #FFF;
    color: rgb(0, 0, 0);
    border: 1px solid rgb(11, 146, 45);
    margin: .5rem !important;
    padding: .5rem !important;

    .apexcharts-tooltip-header{
      text-align: center;
      padding-bottom: .5rem;
      font-weight: 900;
      padding-top: .2rem;
      border-bottom: 1px solid rgb(11, 146, 45);
    }

    .apexcharts-tooltip-text-value{
      text-align: right;
    }
  }
  .conversion-percent{
    width: 10rem !important;
    flex-basis: 10rem;
  }
}
</style>
