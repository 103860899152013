<template>
  <b-card
    no-body
    class="m-0 outlinks"
  >
    <b-card-header class="px-0">
      <b-card-title>
        Where do your users are going to?
      </b-card-title>
    </b-card-header>
    <b-card-body class="px-0">
      <b-list-group
        flush
        class="overflow-auto outlinks-table-h"
      >
        <b-card-text v-if="isEmpty">
          No outlinks for the selected day.
        </b-card-text>
        <vue-perfect-scrollbar :settings="perfectScrollbarSettings">
          <div>
            <b-list-group-item
              v-for="enlace in links"
              :key="enlace.link"
              class="d-flex justify-content-between align-items-center outlinks-list-item-h"
            >
              <a
                target="_blank"
                :href="formatedLink(enlace.link)"
              >{{ enlace.link }}</a>
              <b-badge
                title="Total click"
                variant="primary"
                pill
                class="badge-round"
              >
                {{ enlace.hits }}
              </b-badge>
            </b-list-group-item>
          </div>
        </vue-perfect-scrollbar>
        <b-card-text class="font-weight-bolder mt-1">
          <small>
            *An outlink is a link that leads the visitor away from your website (to another domain). Measured in total clicks.
          </small>
        </b-card-text>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BListGroup, BListGroupItem, BBadge, BCard, BCardHeader, BCardBody, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BListGroup,
    BListGroupItem,
    BCardText,
  },
  props: {
    title: {
      type: String,
      require: true,
      default: 'Outlinks',
    },
    links: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 100,
        minScrollbarLength: 60,
        wheelPropagation: false,
        swipeEasing: true,
      },
    }
  },
  computed: {
    isEmpty() {
      return this.links.length === 0
    },
  },
  methods: {
    formatedLink(url) {
      if (url.includes('http')) {
        return url
      }
      return `https://${url}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import  '@/assets/scss/style';
.outlinks{
  height: $origin-cards-height;
}
.outlinks-table-h {
  max-height: 26rem;

  .outlinks-list-item-h {
    line-height: 3.2;
  }

  .outlinks-list-item-h:last-of-type {
    border-bottom-right-radius: 0.428rem !important;
    border-bottom-left-radius: 0.428rem !important;
  }

  .outlinks-list-item-h:first-of-type {
    border-top-right-radius: 0.428rem !important;
    border-top-left-radius: 0.428rem !important;
  }
}
</style>
