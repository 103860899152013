<template>
  <div class="flowCard box-shadow-2">
    <div
      class="colorIndicator bg-primary"
    />
    <div
      v-if="goalStage.metrics"
      class="cardContainer p-1"
    >
      <div class="section">
        <span
          class="text-primary flex-grow-1"
        >
          <feather-icon
            slot="button"
            icon="FlagIcon"
            size="21"
          /></span>
        <span class="right">
          Goal
        </span>
      </div>
      <div class="section my-1">
        <div class="flex-grow-1">
          <h4 class="cardName">
            {{ goalStage.name }}
          </h4>
          <div class="metrics-container">
            <p class="mb-0 metric">
              <b>{{ goalStage.metrics.nb_visits | numberFormat }}</b> Visitors
            </p>
            <p class="mb-0 metric">
              <b>{{ goalStage.metrics.nb_visits_converted | numberFormat }}</b> Visit converted
            </p>
          </div>
        </div>
        <div class="right">
          <p>
            <span class="goal h4 d-block mb-0">{{ goalStage.metrics.conversion_rate | formatRationPorcentage }}</span><span class="metric">Completed</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  filters: {
    formatRationPorcentage(ratio) {
      return `${Number(ratio * 100).toFixed(2)} %`
    },
    numberFormat: value => {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
      if (!value) {
        return Number(0).toLocaleString()
      }
      return Number(value).toLocaleString()
    },
  },
  props: {
    goalStage: {
      type: Object,
      default: null,
    },
  },
  computed: {
    bouncerRate() {
      if (!this.goalStage.metrics || !this.goalStage.metrics.dropout_rate) {
        return 0
      }
      const bounce = parseInt(this.goalStage.metrics.dropout_rate, 10)
      return bounce
    },
  },
}
</script>

<style lang="scss" scoped>
.flowCard{
  background-color: #fff;
  margin: 1em 1em 2em 1em;
  min-width: 270px;
  width: 270px;
  height: 150px;
  border-radius: 0 6px 6px 0;
  display: flex;

  .colorIndicator {
    height: 100%;
    margin: 0px;
    width: 15px;
    min-width: 15px;
  }
  .cardContainer{
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .section {
      display: flex;
      align-items: center;

      .metrics-container {
        margin-left: .5rem;
      }

      .goal, .cardName{
        font-size: 1.4em;
        font-weight: bold;
      }

      .right{
        width: 35%;
        text-align: center;
      }

      .metric{
        font-size: .85rem;
        line-height: 1.5em;
      }
    }
  }
}
</style>
