<template>
  <b-card
    no-body
    class="m-0 visitor-dropout-card"
  >
    <b-card-body>
      <b-row>
        <b-col
          xl="5"
          lg="5"
        >
          <b-card
            no-body
          >
            <b-card-title>
              Visitors
            </b-card-title>
            <div class="text-right mb-auto">
              Total: <h4 class="d-inline">
                {{ metrics.visits }}
              </h4>
            </div>
            <b-progress
              height="2rem"
              :show-value="true"
              :max="metrics.visits"
              class="my-1"
            >
              <b-progress-bar
                :value="metrics.returningVisits"
                variant="primary"
              />
              <b-progress-bar
                :value="metrics.newVisits"
                variant="warning"
              />
            </b-progress>
            <b-row>
              <b-col
                class="d-flex align-items-center mb-1"
              >
                <div class="pb-label">
                  <b-progress
                    value="100"
                    height="2rem"
                    variant="primary"
                  />
                </div>
                <span class="ml-1">Returning</span>
              </b-col>
              <b-col
                class="d-flex align-items-center mb-1"
              >
                <div class="pb-label">
                  <b-progress
                    value="100"
                    height="2rem"
                    variant="warning"
                  />
                </div>
                <span class="ml-1">New</span>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          xl="7"
          lg="7"
        >
          <b-card
            no-body
          >
            <b-card-title>
              Visitors Behavior
            </b-card-title>
            <b-row align-v="center">
              <b-col
                class="drop-out-chart"
                cols="12"
                sm="6"
              >
                <vue-apex-charts
                  type="donut"
                  height="250"
                  :options="radialOptions"
                  :series="radialSeries.map(serie => serie.value)"
                />
              </b-col>
              <b-col
                class="drop-out-label"
                cols="12"
                sm="6"
              >
                <div
                  v-for="serie in radialSeries"
                  :key="serie.label"
                  class="my-1"
                >
                  <h5>
                    <span
                      :style="{background: serie.color}"
                      class="mr-50 bullet bullet-success bullet-md"
                    />
                    <span>{{ serie.label }}</span> - <span>{{ serie.value }}</span></h5>
                  <b-card-text class="text-muted ml-1">
                    {{ serie.desc }}
                  </b-card-text>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCol, BRow, BCard, BCardBody, BCardTitle, BProgress, BCardText, BProgressBar,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { toRefs, reactive, computed } from '@vue/composition-api'

// eslint-disable-next-line no-unused-vars

export default {
  setup(props) {
    const { metrics } = toRefs(props)
    const othersVisits = metrics.value.visits - metrics.value.bounceCount - metrics.value.dropoutCount - metrics.value.converted

    const radialSeries = reactive([
      {
        color: '#E91E63', label: 'Bounce Visitors', value: metrics.value.bounceCount, desc: 'Visitors immediately leaves the site after consulting only this page',
      },
      {
        color: '#FFA1A1', label: 'Drop Out Visitors', value: metrics.value.dropoutCount, desc: "Visitors leaving this page and didn't complete the goal",
      },
      {
        color: '#ffe700', label: 'Others Visitors', value: othersVisits, desc: "Visitors didn't complete the goal",
      },
      {
        color: '#325ff6', label: 'Converted Visitors', value: metrics.value.converted, desc: 'Visitors that completed the goal',
      },
    ])

    const colors = computed(() => radialSeries.map(serie => (serie.color)))
    const labels = computed(() => radialSeries.map(serie => (serie.label)))
    return {
      radialSeries,
      colors,
      labels,
    }
  },
  components: {
    BCol, BRow, BCard, BCardBody, BCardTitle, BProgress, BCardText, VueApexCharts, BProgressBar,
  },
  props: {
    metrics: {
      type: Object,
      default: () => ({
        visits: 0,
        bounceCount: 0,
        dropoutCount: 0,
        converted: 0,
        returningVisits: 0,
        newVisits: 0,
      }),
    },
  },
  data() {
    return {
      radialOptions: {
        legend: {
          show: false,
        },
        colors: this.colors,
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${Number(val).toFixed(1)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: '"Montserrat", Helvetica, Arial, serif',
                  show: true,
                  offsetY: 5,
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: '"Montserrat", Helvetica, Arial, serif',
                  fontWeight: 800,
                  formatter(val) {
                    return `${val}`
                  },
                },
                total: {
                  show: true,
                  label: 'Total Visitors',
                  fontSize: '1rem',
                  fontWeight: 400,
                  fontFamily: '"Montserrat", Helvetica, Arial, serif',
                  formatter: () => this.metrics.visits,
                },
              },
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: val => `${val}`,
          },
        },
        labels: this.labels,
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 300,
              },
            },
          },
          {
            breakpoint: 720,
            options: {
              chart: {
                height: 240,
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      value: {
                        fontSize: '1.2rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import  '@/assets/scss/style';
.pb-label {
  display: inline-block;
  width: 4rem;
}

@media (min-width: 1200px) and (max-width: 1850px) {
  .drop-out-label{
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .drop-out-chart{
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

</style>
