<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-row>
        <b-col
          md="12"
          lg="4"
        >
          <home-visitors-map
            :values="visitors"
          />
        </b-col>
        <b-col
          md="12"
          lg="4"
        >
          <users-origins
            :values="origins"
          />
        </b-col>
        <b-col
          md="12"
          lg="4"
        >
          <list-group-badge
            :links="outlinks"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BRow, BCardBody,
} from 'bootstrap-vue'
import HomeVisitorsMap from './HomeVisitorsMap.vue'
import UsersOrigins from '@/components/charts/UsersOrigins.vue'
import ListGroupBadge from './ListGroupBadge.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BCard, BRow, BCol, BCardBody, HomeVisitorsMap, ListGroupBadge, UsersOrigins,
  },
  data() {
    return {
      visitors: [],
      outlinks: [],
      origins: [],
    }
  },
  created() {
    this.load()
    this.$eventBus.$on('filterChanges', () => {
      this.load()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('filterChanges')
  },
  methods: {
    ...mapActions('home', [
      'fetchVisitorsMap',
      'fetchOrigins',
      'fetchOutlinks',
    ]),
    load() {
      this.fetchVisitorsMap()
        .then(response => {
          this.visitors = response
        })
      this.fetchOrigins()
        .then(response => {
          this.origins = response
        })
      this.fetchOutlinks()
        .then(response => {
          this.outlinks = response
        })
    },
  },
}
</script>
