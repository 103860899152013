<template>
  <b-card
    no-body
    class="m-0 origins-card"
  >
    <b-card-header class="px-0">
      <b-card-title>Where do your users come from? </b-card-title><small> Visits | Converted visitors</small>

    </b-card-header>
    <b-card-body class="px-0">
      <vue-perfect-scrollbar
        class="origins-container"
        :settings="perfectScrollbarSettings"
      >
        <app-collapse
          accordion
          type="border"
        >
          <app-collapse-item
            v-for="origin in values"
            :key="origin.id"
            title="a"
            :is-visible="origin.isVisible"
            class="colapse-item-origin"
          >
            <template v-slot:header>
              <div class="col-6 col-md-7 px-0 d-flex align-items-center">
                <div
                  :title="formatPercent(origin.hits) + ' Visits'"
                  class="percent-chart"
                >
                  <apexchart
                    type="radialBar"
                    width="40"
                    :options="chartOptions"
                    :series="[percentValue(origin.hits)]"
                  />
                </div>
                <span class="flex-grow-1">{{ origin.label }}</span>
              </div>
              <span
                title="Visits"
                class="text-center col-3 col-sm-3 p-0"
              >
                <feather-icon
                  class="text-primary col-12 p-0"
                  icon="EyeIcon"
                />
                <span>{{ origin.hits | count }}</span>
              </span>

              <span
                title="Conversions"
                class="text-center col-3 col-md-2 p-0"
              >
                <feather-icon
                  class="text-primary col-12 p-0"
                  icon="ShoppingCartIcon"
                  size="16"
                />
                <span>{{ origin.conversions | count }}</span>
              </span>
            </template>
            <users-origins-details :details="origin.detail" />
          </app-collapse-item>
        </app-collapse>
      </vue-perfect-scrollbar>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import apexchart from 'vue-apexcharts'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import UsersOriginsDetails from './UsersOriginsDetails.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    AppCollapse,
    AppCollapseItem,
    apexchart,
    UsersOriginsDetails,
    VuePerfectScrollbar,
  },
  filters: {
    count: value => {
      if (!value) {
        return Number(0).toLocaleString()
      }
      return Number(value).toLocaleString()
    },
  },
  props: {
    values: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 100,
        minScrollbarLength: 60,
        wheelPropagation: false,
        swipeEasing: true,
      },
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: [
          '#2f80ed',
        ],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: '#e9ecef',
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      },
    }
  },
  computed: {
    total() {
      return this.values.reduce((acc, row) => acc + row.hits, 0)
    },
  },
  methods: {
    percentValue(value) {
      // Prevent zero division when total is zero
      if (this.total === 0) {
        return 0
      }

      return (100 * value) / this.total
    },
    formatPercent(value) {
      const percent = this.percentValue(value)
      return `${percent.toFixed(2)}%`
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/style';
.origins-card{
  height: $origin-cards-height;

  .origins-container{
    height: 25rem;
    .card-header{
      height: 12rem;
    }
  }
}

.percent-chart{
  margin-right: .5em;
  @media (min-width: 992px) and (max-width: 1350px) {
    display: none !important;
  }
}
.counts {
    display: flex;
    justify-content: center;
    align-items: center;
}
.counts-column {
  margin-right: 20px;
}
</style>
<style lang="scss">
@import '@/assets/scss/variables/_variables';
.origins-card{
  .origins-container{
    .colapse-item-origin.open {
      .card-header{
        background-color: rgba($primary, .1);
      }
    }
  }
}
</style>
